// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-docker-course-verify-email-js": () => import("./../../../src/pages/docker-course-verify-email.js" /* webpackChunkName: "component---src-pages-docker-course-verify-email-js" */),
  "component---src-pages-docker-for-developers-js": () => import("./../../../src/pages/docker-for-developers.js" /* webpackChunkName: "component---src-pages-docker-for-developers-js" */),
  "component---src-pages-font-testing-tool-js": () => import("./../../../src/pages/font-testing-tool.js" /* webpackChunkName: "component---src-pages-font-testing-tool-js" */),
  "component---src-pages-in-sta-gram-js": () => import("./../../../src/pages/in_sta-gram.js" /* webpackChunkName: "component---src-pages-in-sta-gram-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-platform-index-js": () => import("./../../../src/pages/iot-platform/index.js" /* webpackChunkName: "component---src-pages-iot-platform-index-js" */),
  "component---src-pages-iot-platform-weekly-js": () => import("./../../../src/pages/iot-platform/weekly.js" /* webpackChunkName: "component---src-pages-iot-platform-weekly-js" */),
  "component---src-pages-pill-calendar-js": () => import("./../../../src/pages/pill-calendar.js" /* webpackChunkName: "component---src-pages-pill-calendar-js" */)
}

